

.section-one-donation{
    background: #11998e; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #11998e, #38ef7d); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #11998e, #38ef7d)
}

.section-two-donation{
    background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);

}

.section-three-donation{
    background: #00F260;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #0575E6, #00F260);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #0575E6, #00F260); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.section-four-donation{
    background-image: linear-gradient( 109.6deg,  rgba(223,234,247,1) 11.2%, rgba(244,248,252,1) 91.1% );
}

.color-white{
    margin: 0px;
    color: white;
}

.yellow-color{
    color: rgb(255, 198, 88);
    
}

.green-color{
    color: #11998e;
}

.blue-color{
  color: #082b59;

}

.btn-circle-donation{
    font-size: 20px;
    cursor: pointer;
    user-select: none;
    border-radius: 100px;
    font-weight: bold;
    box-shadow: -1px 3px 6px 0 rgba(0, 0, 0, 0.16);
    color: #ffffff;
    background-color:rgb(255, 198, 88);
}

.btn-circle-donation:active{
    opacity: 0.8;
}

.header-one-donation{
    padding: 0px !important;
    font-size:50px;
}

.header-two-donation{
    padding: 0px !important;
    font-size:30px;
}

.header-three-donation{
    padding: 0px !important;
    font-size:20px;
}



.desc-card-homepage{
    font-size: 16px;
    font-weight: 500;
    color: #6e6e6e;
}

.image-wrapper{
    background-color:rgb(255, 198, 88);
    width: 230px;
    height: 230px;
    border-radius: 250px;
    box-shadow: -1px 3px 6px 0 rgba(0, 0, 0, 0.16);

}

.card-wrapper-homepage{
    border-radius: 25px;
    box-shadow: -1px 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
}







@media (max-width:480px){
    .header-one-donation{
        font-size: 40px;
    }
    .header-two-donation{
        font-size:20px;
    }
    .btn-circle-donation{
        font-size: 14px;
    }
    .img-logo-donation{
        width: 40%;
    }

}