:root {
	--primary-color: #006b53;
	--primary-color--darker: #0f5546;
	--dark-color: black;
  --light-color: white;
  --gold : #f4a024;
  --back-dark : #f7f8fa;
}

/* div{
    border: 1px solid red;
} */

.gold{
    background-color: var(--gold)
}


.bold{
    font-weight: bold
}

.back-dark{
    background-color: var(--back-dark);
}

.font-black-natural {
    color: #727272
}

.font-black-natural-dark{
    color: #666666
}

.font-green{
    color: var(--primary-color)
}

.font-gold{
    color: var(--gold)
}
.font-green-dark{
    color: var(--primary-color--darker)
}

.font-50{
    font-size: 50px
}
.font-40{
    font-size: 40px
}

.font-30{
    font-size: 30px
}

.font-20{
    font-size: 20px
}

.font-10{
    font-size: 10px
}


.garis-bawah-pendek{
    height: 7px;
    width: 20%;
    background-color: var(--gold);
    border-radius: 7px
}



.mybtn-setengah-gold{
    width: 50%;
    padding: 10px 0px 10px;
    background-color: var(--gold);
    color: white;
    font-weight: bolder;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

.green {
    background-color: var(--primary-color)
}


.gradient-green{
    background: rgb(34,193,195);
    background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(29,97,53,1) 100%);
}