:root {
	--primary-color: #006b53;
	--primary-color--darker: #0f5546;
	--dark-color: black;
  --light-color: white;
  --gold : #f4a024;
  --back-dark : #f7f8fa;
}

.judul-form{
    font-size: 30px;
    font-weight: bold;
    color: var(--primary-color);
    border-bottom: 3px solid #f4a024;
    display: inline;
  }

/* .judul-form::after{
    content: '';
    border-bottom: 3px solid var(--gold);
    width: 100px;
    display: inline-block;
    position: relative;
    right  : 200px;
    top: 10px;

} */


.label-data{
  font-size: 12px;
  font-weight: bold;
  /* border-bottom: 1px solid var(--gold); */
  color: var(--primary-color);
}

.data-label{
  font-size: 14px;
  font-weight: lighter;
  text-transform: capitalize;
}

.judul-data{
  color: var(--gold);
  font-size: 18px;
  font-weight: bolder;
  border-bottom: 1px solid #eaedf0;
}

.lihat-image{
  background-color: var(--gold);

  cursor: pointer;
}

.container-card-kelas{
  border: 1px solid #eaedf0;
}

.font-30{
  font-size: 30px;
}

.font-24{
  font-size: 24px;
}

.font-18{
  font-size: 18px;
}